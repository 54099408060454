<template>
    <CCard id="printArea">
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid"/> {{caption}}
        </slot>
      </CCardHeader>
      <CCardBody>
          <List ref="list" 
              :fields="fields"
              :items.sync=validationLogList
              :loading.sync=loading
              showSearch
              :comboboxData=comboboxData
              sorter      
              :defaultSort=defaultSort
              showExcelBtn
              itemsPerPageSelect
              :itemsPerPage=itemPerPage
              showDatePicker
              :defaultSearchDate=searchDate
              :excel=excel
              showPrintBtn
          >      
          <template #no="{index}">
              <td>
                {{totalCnt - ((pageNum -1) * itemPerPage +index)}}
              </td>
          </template>
          <!--아이템별 버튼 재정의-->     
          <template #show_buttons="{item, index}">
            <td class="py-2" >
              <CButton
                size="sm" 
                square
                v-c-tooltip="'상세보기'"
                @click="detailItem(item, index)"
                class="btn btn-primary"
                >
                <CIcon
                  :content="$options.icons.cilMagnifyingGlass"
                />
              </CButton>
            </td>
          </template>   
          </List>
          <page-link ref="page"
              :pageLinkCount=pagePerLink
              :listRowCount=itemPerPage
          />
          <ValidationLogInfoModal v-if="isOpenValidationLogInfoModal" :validationLogKey="validationLogKey" @close-modal="isOpenValidationLogInfoModal = false"/>
        </CCardBody>
    </CCard>
</template>

<script>
import List from "@/views/common/List.vue";
import PageLink from "@/views/common/PageLink.vue";
import CIcon from '@coreui/icons-vue/src/CIconRaw.vue'
import { cilMagnifyingGlass} from '@coreui/icons'
import ValidationLogInfoModal from './ValidationLogInfoModal'
export default {
  name: 'ValidationLogList',
    icons: { cilMagnifyingGlass},
    components: {
        List,
        PageLink,
        CIcon,
        ValidationLogInfoModal
    },
    computed: {
      // 목록에 들어갈 데이터
      validationLogList(){ 
        return this.$store.state.validationLog.validationLogList || []
      },
      searchDate(){
          var beforeDay = new Date()
          beforeDay.setDate(beforeDay.getDate()-7)
          return [beforeDay, new Date()]
      },
      totalCnt(){
        return this.$store.state.validationLog.totalCnt
      }
  },
  // 로컬변수생성
  data() { 
		return{
      searchType:"regId", // 콤보박스 디폴트 값(하기 comboboxData의 value로 설정)
      searchText:"",
      // 검색조건 설정
      comboboxData:[{label: '앱 이름', value: 'appNm'}
                    ,{label: '패키지명', value:'packageNm'}
                    ,{label: '버전', value:'appVersion'}
                    ,{label: 'OS', value:'osType', codeYn:true}
                    ,{label: '사용자고유값', value:'userValue'}],
      // 그리드 컬럼 설정
      fields:[
          { key: 'no', label:'No', _style: { width: '2%'} },
          { key: 'appNm', label:'앱 이름', _style: { width: '10%'} },
          { key: 'osType', label:'OS', _style: { width: '10%'},iconYn:true, codeYn: true  },
          { key: 'packageNm', label:'패키지명', _style: { width: '20%'}},
          { key: 'appVersion', label:'버전', _style: { width: '10%'}},
          { key: 'validationResult', label:'검증', _style: { width: '10%'}, badgeYn: true, codeYn: true },
          { key: 'appTamperYn', label:'App', _style: { width: '10%'}, badgeYn: true, codeYn: true, code: 'tamperYn'},
          { key: 'osTamperYn', label:'OS', _style: { width: '10%'}, badgeYn: true, codeYn: true, code: 'tamperYn' },
          { key: 'debugYn', label:'디버깅', _style: { width: '10%'}, badgeYn: true, codeYn: true, code: 'tamperYn'},
          { key: 'reqDate', label:'요청일시', _style: { width: '10%'}},
          { 
            key: 'show_buttons', 
            label: '', 
            _style: { width: '13%'}, 
            sorter: false,
          },
      ],
      pagePerLink: 5, // 한번에 보여줄 페이지번호 수
      defaultSort: "-reqDate", // 기본 정렬 설정. 정렬 사용시 <List>에 sorter 값 넘겨줘야함
      pageNum: 1, // 페이지번호
      itemPerPage:8,  // 한페이지당 보여줄 데이터수
      loading:false,
      alert:{ 
        noItem:"선택된 아이템이 없습니다.",
      },
      isOpenValidationLogInfoModal: false,
      excel: {
        excelType : '0',  // 엑셀타입 (0: 검증로그, 1: 감사로그, 2: 앱 목록, 3: 앱정책감사로그, 4: 권한그룹 목록, 5: 관리자 목록)
        excelFileNm : "위변조 검증 목록"
      },
      validationLogKey:'',
    }
  },
  // 파라미터
  props: {
    caption: {
      default: '위변조 검증 목록'
    },
  },
  mounted(){
    this.searchList() 
  },
  methods: {
    // 리스트조회
    async searchList(){     
      let payload = this.$refs.list.getParams()
      this.loading=true 
      try {
        await this.$store.dispatch('validationLog/getValidationLogList', payload)
        this.$refs.page.reloadPagination(this.totalCnt)
        this.loading=false
      } catch(error){
        this.$response(error)
        this.loading=false
      }
    },
    async detailItem(item){
        this.validationLogKey = await item.validationLogKey
        this.isOpenValidationLogInfoModal=true
    },
  }
}
</script>