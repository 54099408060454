<template>
  <div class="wrapper">
    <CModal
      :show.sync="isModalOpen"
      :closeOnBackdrop="false"
      :centered="true"
      title=""
      size="lg"
      color="secondary"
    >
      <CRow>
          <CCol lg="12">
            <CCard  class="mb-2">
                <CCardHeader>
                    <strong> 정보 </strong>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol lg="12">     
                            <CRow form class="form-group mb-2">
                                <CCol sm="3">
                                <Strong>OS</Strong>
                                </CCol>
                                <CCol>
                                    <img
                                        :src="$renderer('osType', 2, this.osType)"
                                        width=25
                                        height=25
                                    />
                                </CCol>
                            </CRow>
                            <CRow form class="form-group mb-2">
                                <CCol sm="3">
                                <Strong>앱 이름</Strong>
                                </CCol>
                                <CCol>
                                {{appNm}}
                                </CCol>
                            </CRow>
                            <CRow form class="form-group mb-2">
                                <CCol sm="3">
                                <Strong>패키지명</Strong>
                                </CCol>
                                <CCol>
                                {{packageNm}}
                                </CCol>
                            </CRow>
                            <CRow form class="form-group mb-2">
                                <CCol sm="3">
                                <Strong>버전</Strong>
                                </CCol>
                                <CCol>
                                {{appVersion}}
                                </CCol>
                            </CRow>
                        </CCol>                 
                </CRow>
                </CCardBody>
            </CCard>
            <CCard  class="mb-2">
                <CCardHeader>
                    <strong> 요청정보 </strong>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol lg="6">     
                            <CRow form class="form-group mb-2">
                                <CCol sm="4">
                                <Strong>요청일시</Strong>
                                </CCol>
                                <CCol>
                                    {{$convertDate(this.reqDate)}}
                                </CCol>
                            </CRow>
                            <CRow form class="form-group mb-2">
                                <CCol sm="4">
                                <Strong>단말ID</Strong>
                                </CCol>
                                <CCol>
                                {{deviceId}}
                                </CCol>
                            </CRow>
                            <CRow form class="form-group mb-2">
                                <CCol sm="4">
                                <Strong>사용자고유값</Strong>
                                </CCol>
                                <CCol>
                                {{this.userValue}}
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol lg="6">  
                            <CRow form class="form-group mb-2">
                                <CCol sm="4">
                                <Strong>접속IP</Strong>
                                </CCol>
                                <CCol>
                                {{this.reqIp}}
                                </CCol>
                            </CRow>
                            <CRow form class="form-group mb-2">
                                <CCol sm="4">
                                <Strong>모델명</Strong>
                                </CCol>
                                <CCol>
                                {{this.modelNm}}
                                </CCol>
                            </CRow>
                            <CRow form class="form-group mb-0">
                                <CCol sm="4">
                                <Strong>OS버전</Strong>
                                </CCol>
                                <CCol>
                                {{this.osVersion}}
                                </CCol>
                            </CRow>
                        </CCol>   
                    </CRow>
                </CCardBody>
            </CCard>
            <CCard class="mb-0">
                <CCardHeader>
                    <strong> 검증 결과 </strong>
                </CCardHeader>
                <CCardBody>  
                    <div v-if="validationResult=='0'">                  
                        <h5>
                            <CBadge :color="$renderer('logResult', 1, validationResult)">
                                {{$renderer('logResult', 0, validationResult)}}
                            </CBadge>
                        </h5>
                        {{errorDesc}}
                    </div>
                    <CDataTable
                        v-if="validationResult=='1'"
                        hover
                        striped
                        class="table-align-middle mb-0"
                        :items="items"
                        :fields="fields"
                        no-sorting
                        >
                        <template #policy="{item}">
                            <td>                            
                            <!-- <CSwitch
                                :color="item.policy.color"
                                :checked="item.policy.checked"
                                labelOn="ON" 
                                labelOff="OFF"
                                disabled="true"
                                /> -->
                            
                            <h5><CBadge :color="item.policy.color">{{item.policy.label}}</CBadge></h5>
                            </td>
                        </template>
                        <template #result="{item}">
                            <td>
                            <h5><CBadge :color="item.result.color">{{item.result.label}}</CBadge></h5>
                            </td>
                        </template>
                    </CDataTable>   
                </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      <template #header>
        <h6 class="modal-title">위변조 검증 상세정보</h6>
        <CButtonClose @click="$emit('close-modal')" class="text-white"/>
      </template>
      <template #footer>
        <CButton class="btn btn-primary" @click="$emit('close-modal')">닫기</CButton>
      </template>
    </CModal>
  </div>

</template>

<script>
export default {
    name: 'ValidationLogInfoModal',
    data () {
        return {
            isModalOpen:true,
            fields: [
                { key: 'policyNm', label: '항목'},
                { key: 'policy', label: '정책'},
                { key: 'result', label: '결과'}
            ],    
            appNm:'',
            osType:'',
            packageNm:'',
            appVersion:'',
            deviceId: '',
            reqDate:'',
            reqIp:'',
            userValue:'',
            modelNm:'',
            osVersion:'',  
            appTamperDetectYn:'',  
            osTamperDetectYn:'',  
            debugDetectYn:'',
            appTamperYn:'',  
            osTamperYn:'',  
            debugYn:'',
            validationResult:'',
            errorDesc:'',
                      
        }
    },
    props: {
        validationLogKey: String
    },
    computed:{
        items(){ 
            var items=  [
                {
                    policyNm: '앱 위변조 탐지', 
                    policy: { color: this.$renderer('detectYn', 1, this.appTamperPolicy), label: this.$renderer('detectYn', 0, this.appTamperPolicy)}, 
                    result: { color: this.$renderer('tamperYn', 1, this.appTamperYn), label: this.$renderer('tamperYn', 0, this.appTamperYn)}
                },
                {
                    policyNm: 'OS 위변조 탐지', 
                    policy: { color: this.$renderer('detectYn', 1, this.osTamperPolicy), label: this.$renderer('detectYn', 0, this.osTamperPolicy)},
                    result: { color: this.$renderer('tamperYn', 1, this.osTamperYn), label: this.$renderer('tamperYn', 0, this.osTamperYn)}
                },
                {
                    policyNm: '디버깅 탐지', 
                    policy: { color: this.$renderer('detectYn', 1, this.debugPolicy), label: this.$renderer('detectYn', 0, this.debugPolicy)},
                    result: { color: this.$renderer('tamperYn', 1, this.debugYn), label: this.$renderer('tamperYn', 0, this.debugYn)}
                }
            ]
            return items
        }
    },
    mounted(){
        this.searchValidationLog() 
    },
    methods: {
        async searchValidationLog(){
          
            this.loading=true 
            try {
                await this.$store.dispatch('validationLog/getValidationLogInfo', this.validationLogKey)
                var validationLogInfo = this.$store.state.validationLog.validationLogInfo
                this.appNm = validationLogInfo.appNm
                this.osType = validationLogInfo.osType
                this.packageNm = validationLogInfo.packageNm
                this.appVersion = validationLogInfo.appVersion
                this.deviceId= validationLogInfo.deviceId
                this.reqDate = validationLogInfo.reqDate
                this.reqIp = validationLogInfo.reqIp
                this.userValue = validationLogInfo.userValue
                this.modelNm = validationLogInfo.modelNm
                this.osVersion = validationLogInfo.osVersion
                this.appTamperPolicy = validationLogInfo.appTamperPolicy
                this.osTamperPolicy = validationLogInfo.osTamperPolicy
                this.debugPolicy = validationLogInfo.debugPolicy
                this.appTamperYn = validationLogInfo.appTamperYn
                this.osTamperYn = validationLogInfo.osTamperYn
                this.debugYn = validationLogInfo.debugYn
                this.validationResult = validationLogInfo.validationResult
                this.errorDesc = validationLogInfo.errorDesc
                this.loading=false
            } catch(error){
                this.$response(error)
                this.loading=false
            }           
        },
        detailItem(){
            this.isOpenModifyAppModal=true
        },
    }
}
</script>
